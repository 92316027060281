import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "rawbike" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "elcyklar-från-rawbike"
    }}>{`Elcyklar från RAWBIKE`}</h1>
    <p>{`RAWBIKE elcykel är den perfekta lösningen för svenska cyklister som söker både kvalitet och innovation i sitt cykelliv. Som ett svensktillverkat varumärke, levererar RAWBIKE elcyklar som är skräddarsydda för att passa stadsmiljöer. Upptäck modeller som RAWBIKE CITY, optimerad för problemfri stadspendling, och RAWBIKE Hopfällbar Elcykel 250E, som erbjuder enkel transport och robust stöldskydd. Dessa elcyklar är ett resultat av hållbar teknik och innovation som kombinerar kraftfulla RAWBIKE motor-komponenter och smart pedalassistans, vilket ger en effektiv och energibesparande cykeltur. För de som värdesätter både stil och prestanda, erbjuder RAWBIKE URBAN-serien en blandning av elegant design och hög komfort. Oavsett vilka dina behov är, har RAWBIKE en elcykel som integrerar sömlöst i ditt moderna liv, samtidigt som det gör varje cykeltur till ett nöje.`}</p>
    <h2>Introduktion till RAWBIKE</h2>
    <p>RAWBIKE är en pionjär inom elcykelindustrin, känt för sitt engagemang för innovation och kvalitet. Som ett svensktillverkat varumärke, står RAWBIKE ut som en ledare i branschen, erbjuder elcyklar som är noggrant anpassade för både stadsmiljö och fritidsanvändning. Med sitt fokus på att kombinera snygg design med praktiska funktioner, har RAWBIKE etablerat sig som ett pålitligt val för svenska konsumenter. Varumärkets omfattande sortiment, inklusive den populära RAWBIKE elcykel-serien, är utformat för att möta moderna behov och förbättra den dagliga livsstilen hos sina användare.</p>
    <p>RAWBIKE:s mål och värderingar genomsyras av ett starkt engagemang för hållbarhet och teknologisk innovation. Företaget strävar efter att minska miljöpåverkan och främja en grönare framtid genom sina produkter. Dessa värderingar reflekteras tydligt i deras svensktillverkade elcyklar, som kombinerar toppmodern teknik med robust design. Varje RAWBIKE elcykel, inklusive deras "elcykel stadsmiljö"-modeller, är utrustad med funktioner som GPS-baserat stöldskydd och energieffektiva motorer. Denna hängivenhet till hållbar innovation gör RAWBIKE till en föregångare i utvecklingen av framtidens transportlösningar i Sverige.</p>
    <h2>Serier och Funktioner</h2>
    <p>RAWBIKE elcyklar erbjuder tre unika serier som understryker företagets engagemang för kvalitet och innovation inom svensk cykeltillverkning. Varje serie är noggrant designad för att möta olika behov och användningsfall, från stadspendling till äventyr på helgerna. <strong>RAWBIKE CITY</strong> är den perfekta elcykeln för liv och rörelse i stadsmiljö tack vare sin kompakta design och användarvänliga funktioner, såsom det avancerade AVS-systemet. Om du letar efter flexibilitet är <strong>RAWBIKE Hopfällbar Elcykel 250E</strong> ett utmärkt val, utformad för enkel förvaring och transport, vilket gör den idealisk för daglig pendling. För de som prioriterar stil och prestanda kombinerar <strong>RAWBIKE URBAN</strong> elegant svensk design med hög komfort och funktionalitet, perfekt för såväl snabba ärenden som avkopplande turer. Dessa serier av RAWBIKE elcyklar ger kunder möjligheten att välja den modell som bäst passar deras individuella behov och livsstil.</p>
    <h2>RAWBIKE CITY Serie</h2>
    <p>RAWBIKE CITY-serien är den perfekta lösningen för stadsbor som söker en smidig och effektiv rånöring i stadsmiljön. Den svensktillverkade RAWBIKE elcykeln är optimerad för stadspendling, med en kompakt design som gör den enkel att manövrera även på trafiktäta gator. Utrustad med en tystgående och robust 250W-motor kan du njuta av en problemfri och energibesparande åktur samtidigt som du når en toppfart på 25 km/h. Denna elcykel för stadsmiljö är inte bara funktionell, utan också trendigt designad i en matte black finish. Med ett innovativt AVS-system från Atran Velo kan du enkelt justera och anpassa cykeln med olika tillbehör, vilket gör den idealisk för den varierande vardagen i staden. Med en räckvidd på upp till 50 km är RAWBIKE CITY-serien din pålitliga kompis för varje daglig pendling.</p>
    <p>Förutom dess tekniska överlägsenhet, erbjuder RAWBIKE CITY-serien en unik mix av stil och funktionalitet, vilket gör den till både ett transportmedel och en modeaccessoar. Den eleganta designen kombineras med praktiska funktioner, vilket gör att cykeln smälter in lika bra på de trendigaste gatorna som på kontoret. Lättillgängliga AVS-accessoarer möjliggör för användare att snabbt och enkelt anpassa cykeln för olika användningsområden, medan den stilrena framtoningen säkerställer att du gör ett statement varje gång du cyklar. Vare sig du pendlar till jobbet eller tar en tur runt stan är RAWBIKE CITY det stiliga och funktionella valet för den moderna elcyklisten.</p>
    <h2>RAWBIKE Hopfällbar Elcykel 250E Serie</h2>
    <p>RAWBIKE Hopfällbar Elcykel 250E representerar det yttersta inom mobilitet och flexibilitet, vilket gör den till en ideal elcykel för stadsmiljö. Denna hopfällbara elcykel erbjuder en innovativ design som enkelt kan fällas ihop, vilket underlättar både förvaring och transport på olika typer av kollektivtrafik. Utrustad med en kraftfull RAWBIKE motor på 250W och ett pålitligt 48V/16Ah batteri, klarar cykeln upp till 50 km på en enda laddning. Dess robusta däck, speciellt anpassade för nordiska förhållanden, garanterar säker framkomlighet oavsett väder – perfekt för alla årstider. En av de mest framstående funktionerna är GPS-stöldskyddet, som ger extra trygghet och säkerhet i stadsmiljö, och ser till att du alltid kan ha koll på din cykels position.</p>
    <p>Utöver sin tekniska styrka, erbjuder RAWBIKE Hopfällbar Elcykel 250E praktiska funktioner som förenklar den dagliga pendlingen och gör den till en hållbar investering för framtiden. Med sin lättviktsram och möjligheten att enkelt packa ner eller transportera i bil, är denna elcykel perfekt för de som vill kombinera cykel och kollektivtrafik. Den utgör ett miljövänligt val för den moderna pendlaren, samtidigt som den levererar en högkvalitativ körupplevelse. Investera i en RAWBIKE elcykel och upptäck den perfekta balansen mellan teknik, komfort och miljömedvetenhet för stadslivet. Genom att välja RAWBIKE Hopfällbar Elcykel 250E investerar du i framtidens transportlösningar.</p>
    <h2>RAWBIKE URBAN Serie</h2>
    <p>RAWBIKE URBAN-serien står som en symbol för svensk ingenjörskonst och design, där hög prestanda möter oöverträffad komfort. Med en energieffektiv RAWBIKE motor på 250W och ett sofistikerat pedalassistanssystem, erbjuder denna serie den perfekta elcykeln för både ärenden i den täta stadsmiljön och för avslappnade cykelturer en solig dag. Oavsett om du navigerar genom trafikerade gator eller cruisear längs strandpromenader, ser RAWBIKE URBAN till att varje resa är smidig och njutbar. Denna elcykel för stadsmiljö levererar en maxhastighet på 25 km/h, vilket både förenklar och förkortar din dagliga pendling.</p>
    <p>Den estetiska utformningen av RAWBIKE URBAN är lika imponerande som dess funktionalitet. Cykeln erbjuds i en rad sofistikerade färger såsom matte black, ivory white och sand beige, vilket gör det enkelt att matcha med din personliga stil. De integrerade ljusen och den tydliga LCD-skärmen förstärker cykelns moderna attribut, medan de robusta 26-tums aluminiumfälgarna säkerställer en komfortabel och stabil färd. Denna kombination av stil och teknik gör RAWBIKE URBAN till ett idealiskt val för det moderna stadslivet, där innovation och elegans möts.</p>
    <h2>Så väljer du rätt RAWBIKE-serie</h2>
    <p>Att välja rätt RAWBIKE elcykel kan kännas som en utmaning med flera olika serier att överväga. Här är några praktiska tips för att identifiera vilken RAWBIKE som bäst passar dina behov och livsstil. Börja med att fundera över hur du mestadels kommer att använda din elcykel. Är det för daglig pendling i en <strong>elcykel stadsmiljö</strong>, eller letar du efter något smidigt och lätt att transportera?</p>
    <p>För dig som prioriterar <strong>stadspendling och stil</strong>, är <strong>RAWBIKE CITY</strong> det idealiska valet. Denna svensktillverkade elcykel är utrustad med en tyst 250W-motor, perfekt för stadsmiljö, och erbjuder en design som fungerar både som transportmedel och modeaccessoar. Om du däremot behöver en cykel som är enkel att förvara och ta med sig, då är <strong>RAWBIKE Hopfällbar Elcykel 250E</strong> med hopfällbarhet och GPS-stöldskydd ett utmärkt alternativ för dynamiska användare.</p>
    <p>För den som söker en balans mellan prestanda och komfort, är <strong>RAWBIKE URBAN</strong> med svensk design och pedalassistans ett bra val. Den kombinerar hög prestanda med bekvämlighet för både ärenden och fritid. Tänk på vilka extra funktioner du värdesätter, som robusta däck för nordiska förhållanden eller integrerade ljus för säker kvällscykling. Med dessa överväganden kan du enkelt hitta den RAWBIKE-serie som bäst matchar ditt liv och dina behov.</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      